import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Tungsten Rounded",
  "tags": "Typography",
  "date": "2014-07-15T00:00:00.000Z",
  "link": "http://typography.com/fonts/tungsten-rounded/overview/",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/assets/articles/tungsten-rounded/tungsten-rounded-12.png",
        "alt": null
      }}></img></p>
    <p>{`It’s no secret that I’m a complete sucker for `}<a parentName="p" {...{
        "href": "http://www.typography.com/fonts/tungsten-rounded/overview/"
      }}>{`Tungsten`}</a><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`. It might very well may be one of my favorite typeface ever made. It’s nice to see `}<a parentName="p" {...{
        "href": "http://www.typography.com/"
      }}>{`Hoefler & Co`}</a><sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` add new styles to their iconic typefaces. Hopefully a non-condensed version of Tungsten is coming next.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`I’ve been using Tungsten Semi-Bold in my logo for years now. Still looks as good.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Still not getting used to it.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      